import Routes from '../routes.mjs';

const replacePageName = (routePage) => {
  const routesMatchingPageName = Routes
    .routes
    .filter(({ page }) => page === routePage);

  if (routesMatchingPageName.length === 0) {
    return routePage;
  }

  return routesMatchingPageName.length === 1
    ? routesMatchingPageName[0].name
    : routesMatchingPageName[0].name.replace(/\..+$/, '');
};

export default replacePageName;
